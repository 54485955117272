<template>
    <div>
        <b-modal size="xl" v-model="modalShow" class="h-100" :no-close-on-esc="true" :no-close-on-backdrop="true" :hide-header-close="true">
            <template #default>
                <b-overlay :show="show">
                    <div v-if="message !== null && message">
                        <div class="d-flex justify-content-start">
                            <div class="d-flex justify-content-start align-items-center">
                                <b-badge class="mr-1" :variant="getPriority(message.priority).variant">
                                    <div class="d-flex align-items-center">
                                        <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" :icon="getPriority(message.priority).icon"/>
                                        <div style="font-size: 14px;line-height: 15px!important; height: 15px!important;">{{ getPriority(message.priority).name }}</div>
                                    </div>
                                </b-badge>
                                <b-badge :variant="getType(message.type).variant">
                                    <div class="d-flex align-items-center">
                                        <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" :icon="getType(message.type).icon"/>
                                        <div style="font-size: 14px;line-height: 15px!important; height: 15px!important;">{{ getTypeName(message.type) }}</div>
                                    </div>
                                </b-badge>
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="title-class">{{ message.title }}</div>
                            </div>
                            <div class="w-100 mt-2">
                                <div class="ql-snow">
                                    <div class="ql-editor" v-html="message.content"/>
                                </div>
                            </div>
                        </div>
                        <div v-if="attachmentsReady && attachments.length > 0">
                            <hr/>
                            <div style="font-size: 16px;">
                                <div class="d-flex justify-content-between" style="font-size: 14px; font-weight: bold; padding-bottom: 10px; padding-top: 10px;">
                                    <div>
                                        {{ $t('print.messsage.attachments') }} ({{attachments.length}}):
                                    </div>

                                    <div>
                                        <b-button variant="primary" style="height: 22px; padding: 2px 5px;" @click="downloadAll"><feather-icon icon="DownloadIcon" style="cursor: pointer;"/>{{$t('print.message.download_all')}}</b-button>
                                    </div>
                                </div>
                                <div class="mt-2"  style="display: flex; flex-wrap: wrap;">
                                    <div style="font-size: 16px;">
                                        <div style="display: flex; flex-wrap: wrap;">
                                            <div class="mb-1 mr-1 mouseHoverAttachment" style="position:relative;" v-for="(file, id) in attachments" :key="'slika_' + file.id" @mouseenter="hoveredItem = id" @mouseleave="hoveredItem = -1" @click="openAttachments(id)">
                                                <div style="margin-left: 3px; margin-right: 3px; border: 1px solid #d4d4d4;; position: absolute; z-index: 1100; background: #d9d9d9" v-if="hoveredItem == id" >
                                                    <div style="height: 9rem; width: 12rem; cursor: pointer; overflow-y: hidden; overflow-x: hidden;">
                                                        <div class="d-flex">
                                                            <div style="padding: 8px; " class="d-flex justify-content-center pt-1">
                                                                <feather-icon :icon="getFileIcon(file)"/>
                                                            </div>
                                                            <div class="d-flex flex-column align-items-start" style="width: 100%; height: 8rem;">
                                                                <div class="cutText mb-auto" style="margin-top: 13px;">
                                                                    {{ file.name }}
                                                                </div>
                                                                <div style="width: 100%; padding-bottom: 0;">
                                                                    <div style="font-size: 12px; font-weight: bold; ">
                                                                        {{getFileSize(file.size)}}
                                                                    </div>
                                                                    <b-button style="width: 100%; height: 25px; padding: 0;" >
                                                                        <div class="d-flex align-items-center justify-content-center" @click="getMessageAttachment(file)">
                                                                            <feather-icon variant="primary" icon="DownloadIcon" style="cursor: pointer;"/>
                                                                        </div>
                                                                    </b-button>
                                                                </div>
                                                            </div>
                                                            <div style="padding: 14px; " class="d-flex justify-content-center pt-1">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="borderAttachment" style="margin-left: 3px; margin-right: 3px;">
                                                    <div class="w-100" style="overflow: hidden; height: 7rem; width: 12rem!important;" v-if="file.type.includes('image')">
                                                        <img alt="" style="cursor: pointer; object-fit: cover; width: 100%; height: 100%;" :src="file.url" />
                                                    </div>
                                                    <div v-else-if="file.type === 'application/pdf'">
                                                        <div style="height: 7rem; width: 12rem;">
                                                            <pdf style="height: 100%; overflow: hidden;" :src="file.url" />
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <div style="height: 7rem; width: 12rem; cursor: pointer; overflow-y: hidden;" class="d-flex justify-content-center align-items-center">
                                                            <feather-icon icon="FileIcon" size="50" color="#b7b7b7"/>
                                                        </div>
                                                    </div>
                                                    <div style="width: 100%;height: 2rem;" class="d-flex align-items-center attachmentShortInfo">
                                                        <div style="padding-left: 5px; padding-right: 5px; margin: 0;">
                                                            <feather-icon :icon="getFileIcon(file)" style="cursor: pointer;"/>
                                                        </div>
                                                        <div class="cutTextOne" style="width: 8rem; margin: 0;">
                                                            {{ file.name }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
            </template>
            <template #modal-footer>
                <div class="d-flex justify-content-center align-items-center w-100">
                    <b-form-checkbox v-model="has_read" style="font-size: 16px; font-weight: bold;" >{{ $t('general.read_confirmation') }}</b-form-checkbox>
                </div>
                <div class="d-flex justify-content-center align-items-center w-100">
                    <b-button variant="warning" :disabled="!has_read" @click="hideModal();" >Zapri</b-button>
                </div>
            </template>
        </b-modal>

        <image-display-modal-vue ref="imageModal" v-if="allIsLoaded" :attachments="attachments" @close="showModal" />
    </div>
</template>
<script>
    import { BModal, BButton, BBadge, BOverlay, BFormCheckbox } from 'bootstrap-vue'
    import pdf from 'vue-pdf'
    import ImageDisplayModalVue from '@/views/SystemMessages/components/AttachmentsViewGallery.vue'
    import * as Sentry from '@sentry/vue'
    import {getVariant, getFileSize, getPriority, getType, getTypeName} from '@/libs/Messages'

    export default {
        components: {ImageDisplayModalVue, BModal, BButton, BBadge, BOverlay, BFormCheckbox, pdf},
        props:{
            message: {
                Type: Object,
                require: true
            }
        },
        data() {
            return {
                allIsLoaded: false,
                show:false,
                modalShow:true,
                has_read:false,
                attachments:[],
                attachmentsDownloaded: {},
                hoveredItem: -1,
                attachmentsReady: false,
                getVariant,
                getFileSize,
                getPriority,
                getType,
                getTypeName
            }
        },
        methods:{
            showModal() {
                this.modalShow = true
                this.has_read = false
            },
            async hideModal() {
                this.modalShow = false
                await this.markAsRead()
                //this.modalShow = false
            },
            async loadData() {
                try {
                    if (this.message.attachments) {
                        await this.loadAttachments()
                    }
                } catch (err) {
                    this.$printError(this.$t('print.error.general'), 3000)
                } finally {
                    this.show = false
                    this.allIsLoaded = true
                }

            },
            async markAsRead() {
                try {
                    await this.$http.put(`/api/client/v1/messages/read/mark_read/${this.message.id}/true`)
                    await this.$http.put(`/api/client/v1/messages/read_urgent/mark_read/${this.message.id}/true`)
                    await this.$store.dispatch('messages/set_messages')
                    await this.$store.dispatch('messages/set_unread_urgent_messages')
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'), 3000)
                } finally {
                    this.$emit('close')
                }
            },
            async loadAttachments() {
                try {
                    this.attachments = []

                    const res = await this.$http.get(`/api/client/v1/messages/${this.message.id}/attachments`)
                    const downloadedImages = res.data.message_attachments ?? []
                    for (const downloadedImage of downloadedImages) {

                        const attachmentResp = await this.$http.get(`/api/client/v1/messages/${this.message.id}/attachment/${downloadedImage.minio_id}`, {responseType: 'blob'})
                        const url = window.URL.createObjectURL(new Blob([attachmentResp.data]))

                        if (downloadedImage.file_type === 'application/pdf') {
                            const pdf_src = await pdf.createLoadingTask(url)
                            await pdf_src.promise.then((pdf) => {
                                const numPages = pdf.numPages

                                this.attachments.push({
                                    url: pdf_src,
                                    org_url: url,
                                    pages: numPages,
                                    name: downloadedImage.name,
                                    id: downloadedImage.id,
                                    type: downloadedImage.file_type,
                                    size: downloadedImage.byte_size,
                                    uploaded: true
                                })
                            })


                        } else {
                            this.attachments.push({
                                url,
                                name: downloadedImage.name,
                                id: downloadedImage.id,
                                type: downloadedImage.file_type,
                                size: downloadedImage.byte_size,
                                uploaded: true
                            })
                        }
                    }

                    this.attachmentsReady = true

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'), 3000)
                }
            },
            async getMessageAttachment(doc) {
                try {
                    this.show = true

                    const link = document.createElement('a')

                    if (!doc.org_url) {
                        link.href = doc.url
                    } else {
                        link.href = doc.org_url
                    }

                    link.setAttribute('download', `${doc.name}`) //or any other extension

                    document.body.appendChild(link)
                    link.click()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'), 3000)
                } finally {
                    this.show = false
                }
            },
            async downloadAll() {
                try {
                    this.show = true

                    const response = await this.$http.get(`/api/client/v1/messages/${this.message.id}/attachment/bulk`, { responseType: 'blob' }) 
                    
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')

                    link.href = url
                    link.setAttribute('download', `${this.message.title}_priponke.zip`)

                    document.body.appendChild(link)
                    link.click()
                    
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'), 3000)
                } finally {
                    this.show = false
                } 
            },
            getClient(id) {
                const client = this.clients.find(client => client.id === id)
                if (client) {
                    return client.alias
                } else {
                    return ''
                }
            },
            getFileIcon(file) {
                try {
                    switch (file.type.split('/')[0]) {
                    case 'image':
                        return 'ImageIcon'
                    case 'audio':
                        return 'HeadphonesIcon'
                    case 'application':
                        if (file.type === 'application/pdf') return 'FileTextIcon'
                        else return 'PackageIcon'
                    case 'video':
                        return 'FilmIcon'
                    default:
                        return 'PackageIcon'
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'), 3000)
                    return 'PackageIcon'
                }
            },
            openAttachments(id) {
                this.modalShow = false
                this.hoveredItem = -1
                this.$refs.imageModal.open(id)
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>
<style scoped lang="scss">
.textMedium{
    font-size: 18px;
    font-weight: bold;
  }

  .light-layout {
    .mouseHoverAttachment{
      background: #d9d9d9;
      border: 1px solid #d9d9d9;
      color: #646464;
    }

    .attachmentShortInfo{
      background: #d9d9d9;
      border: #d9d9d9;
      color: #2a2e30;
    }

    .borderAttachment{
      border: 2px solid #d9d9d9 !important;
    }
  }

  .dark-layout {
    .mouseHoverAttachment{
      background: #7c7c7c;
      border: 1px solid #7c7c7c;
      color: #2a2e30;
    }

    .attachmentShortInfo{
      background: #7c7c7c;
      border: #7c7c7c;
      color: #2a2e30;
    }

    .borderAttachment{
      border: 2px solid #7c7c7c !important;
    }
  }

  .cutText{
    margin-top: 5px;
    font-weight: bold;
    font-size: 13px;
    padding-right: 5px;
    overflow: hidden;
    word-wrap: anywhere;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    line-height: 1em;
    height: 3em;
    -webkit-box-orient: vertical;
  }

  .cutTextOne{
    margin-top: 5px;
    font-weight: bold;
    font-size: 13px;
    padding-right: 5px;
    overflow: hidden;
    word-wrap: anywhere;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    line-height: 1em;
    -webkit-box-orient: vertical;
  }
.title-class{
    font-size: 35px;
    margin-bottom: 1rem;
    font-weight: bold;
}
</style>
<!--<style>-->
<!--@media (min-width: 1200px) {-->
<!--    [dir="ltr"] .modal-xl {-->
<!--        margin-left: auto;-->
<!--        margin-right: auto;-->
<!--    }-->
<!--}-->
<!--</style>-->